<template>
  <div class="container">
    <v-container id="dashboard" fluid tag="section">

      <v-row>
        <v-col cols="12">
          <!-- <FeatureNotifications/> -->
          <SuperAdminDashboard v-if="currentUserRole === 'super_admin'" />
          <AccountManagerDashboard v-else />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import auth from "../../auth";
import * as conf from "../../conf.yml";
import axios from "axios";
import AccountManagerDashboard from "./AccountManagerDashboard";

import SuperAdminDashboard from "./SuperAdminDashboard.vue";

import FeatureNotifications from "./FeatureNotifications.vue";


export default {
  name: "Dashboard",
  data: () => ({
    currentUserRole: null,
  }),
  mounted() {
    this.currentUserRole = auth.user.role.name;
  },
  components: {
    AccountManagerDashboard: AccountManagerDashboard,
    SuperAdminDashboard: SuperAdminDashboard,
    // FeatureNotifications: FeatureNotifications,

  },
};
</script>
