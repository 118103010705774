<template>
  <v-row class="md-layout md-gutter super-admin-dashboard">
    <v-col
      cols="12"
      sm="6"
      md="6"
      lg="4"
      xl="3"
      v-for="item in items"
      :key="item.title"
    >
      <router-link :to="item.path">
        <div class="md-layout-item super-admin-dashboard__item">
          <md-card class="card-style">
            <div
              :class="
                item.id % 2 === 0
                  ? 'icon-container-green'
                  : 'icon-container-purple'
              "
            >
              <md-card-media md-medium class="media-content">
                <img v-if="item.image" :src="item.image" :alt="item.alt" />
                <v-icon v-if="item.icon" size="100" color="#5B3D87">{{
                  item.icon
                }}</v-icon>
              </md-card-media>
              <div v-if="item.count" class="count-indicator">
                {{ item.count }}
              </div>
            </div>
            <md-card-content>
              <div class="title-style">{{ item.title }}</div>
              <div class="subtitle-style">{{ item.description }}</div>
            </md-card-content>
          </md-card>
        </div>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
import notification from "@/notification";
import restAdapter from "../../restAdapter";

export default {
  name: "SuperAdminDashboard",
  data: () => ({
    items: [
      {
        id: 1,
        title: "Verify Accounts",
        description: "Verify accounts",
        path: "/app/verify_accounts",
        image: require(`../../assets/dashboard-icons/verify_accounts.png`),
        alt: "Verify accounts",
      },
      {
        id: 2,
        title: "Global Account List",
        description: "Global account list",
        path: "/app/global_list",
        image: require(`../../assets/dashboard-icons/account_manager_dashboard.png`),
        alt: "Global list",
      },
      {
        id: 3,
        title: "Account Requests",
        description: "View account requests",
        path: "/app/account_requests",
        image: require(`../../assets/dashboard-icons/global_list.png`),
        alt: "Verify accounts",
        count: 0,
      },
      {
        id: 4,
        title: "Verify Transactions",
        description: "View verification requested transactions",
        path: "/app/verify_assignments",
        image: require(`../../assets/dashboard-icons/global_list.png`),
        alt: "Verify transactions",
        count: 0,
      },
      {
        id: 5,
        title: "Verify Styles",
        description: "View verification requested styles",
        path: "/app/verify_inquiries",
        image: require(`../../assets/dashboard-icons/verify_styles.png`),
        alt: "Verify styles",
        count: 0,
      },
      {
        id: 6,
        title: "Analytics",
        description: "View platform analytics",
        path: "/app/analytics",
        image: require(`../../assets/dashboard-icons/analytics.png`),
        alt: "Platform analytics",
        count: 0,
      },
      {
        id: 7,
        title: "Factory Requests",
        description: "View requested factories",
        path: "/app/factory_requests",
        image: require(`../../assets/dashboard-icons/verify_styles.png`),
        alt: "Factory requests",
        count: 0,
      },
    ],
  }),

  methods: {
    async fetchCounts() {
      try {
        const dashboardDetailsResponse = await restAdapter.get(
          "/api/dashboard_details"
        );

        const counts = dashboardDetailsResponse.data;

        this.$set(this.items[3], "count", counts.assignments_count);
        this.$set(this.items[4], "count", counts.verification_requests_count);
        this.$set(this.items[2], "count", counts.organization_requests_count);
      } catch (error) {
        notification.error("Failed to fetch counts", error);
      }
    },
  },

  mounted() {
    this.fetchCounts();
  },
};
</script>

<style lang="scss" scoped>
.super-admin-dashboard {
  a:not(.md-button):hover {
    text-decoration: none;
  }

  .md-card.md-theme-default:hover {
    .title-style {
      font-weight: 700;
      font-size: 20px;
    }
    box-shadow: 0px 29px 15px rgba(169, 169, 194, 0.19);
  }

  .card-style {
    background: #ffffff;
    box-shadow: 0px 6px 11px rgba(187, 187, 219, 0.19);
    border-radius: 8px;
  }

  .icon-container-purple {
    background-color: #ebdaff;
    border-radius: 8px 8px 0px 0px;
  }
  .icon-container-green {
    background-color: #a5e8e6;
    border-radius: 8px 8px 0px 0px;
  }
  .media-content {
    justify-content: center;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 40px;
  }

  .title-style {
    color: #473068;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
  }

  .subtitle-style {
    color: #473068;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .count-indicator {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #5b3d87;
    color: white;
    font-size: 14px;
    padding: 3px 8px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
</style>
