<template>
  <div>
    <v-row class="md-layout md-gutter account-manager-dashboard">
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        v-for="item in filteredItems"
        :key="item.title"
      >
        <router-link
          :to="
            item.code === 'create_style' && isCreateStyleButtonDisabled
              ? ''
              : item.path
          "
          :class="`hover-${item.code}`"
        >
          <div class="md-layout-item account-manager-dashboard__item">
            <md-card
              class="card-style"
              :style="
                item.code === 'create_style' && isCreateStyleButtonDisabled
                  ? 'pointer-events: none; opacity: 0.4; box-shadow: none;'
                  : ''
              "
            >
              <div
                :class="
                  item.code === 'create_style' && isCreateStyleButtonDisabled
                    ? 'icon-container-disabled'
                    : item.id % 2 === 0
                    ? 'icon-container-green'
                    : 'icon-container-purple'
                "
              >
                <md-card-media
                  md-medium
                  :class="
                    item.code === 'create_collection'
                      ? 'media-content-management-dashboard'
                      : 'media-content'
                  "
                >
                  <create-style-icon
                    v-if="
                      item.code === 'create_style' &&
                      !isCreateStyleButtonDisabled
                    "
                  />
                  <create-collection-icon
                    v-else-if="item.code === 'create_collection'"
                  />
                  <img v-else :src="item.image" :alt="item.alt" />
                </md-card-media>
              </div>
              <md-card-content>
                <div class="title-style">{{ item.title }}</div>
                <div class="subtitle-style">{{ item.description }}</div>
              </md-card-content>
            </md-card>
            <div
              v-if="item.code === 'create_style' && isCreateStyleButtonDisabled"
              class="item-tooltip"
            >
              Please create a collection first
            </div>
          </div>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import restAdapter from "../../restAdapter";
import auth from "../../auth";

export default {
  name: "AccountManagerDashboard",
  data() {
    return {
      items: [
        {
          id: 1,
          code: "create_collection",
          title: "Create Collection",
          description: "Create an assortment of fashion styles",
          path: "/app/collection/new",
          image: require(`../../assets/dashboard-icons/create_collection.png`),
          alt: "Create Collection",
        },
        {
          id: 2,
          code: "create_style",
          title: "Create Style",
          description: "Create an unique design for each idea",
          path: "/app/inquiry/new",
          image: require(`../../assets/dashboard-icons/create_style.png`),
          alt: "Create style",
        },
        {
          id: 4,
          code: "view_collections",
          title: "View Collections",
          description: "Manage all collections and styles",
          path: "/app/collections/:collection_type?/:query?",
          image: require(`../../assets/dashboard-icons/account_manager_dashboard.png`),
          alt: "View collections",
        },
      ],
    };
  },

  computed: {
    isCreateStyleButtonDisabled() {
      return (
        !this.$store.state.collectionCounts
          .number_of_collections_created_for_others &&
        !this.$store.state.collectionCounts.number_of_collections_owned
      );
    },
    filteredItems() {
      if (this.isBrand()) {
        const filteredItems = this.items.filter(
          (item) =>
            item.code === "create_collection" ||
            item.code === "create_style" ||
            item.code === "view_collections"
        );

        const order = ["create_collection", "create_style", "view_collections"];

        const sortedItems = filteredItems.sort(
          (a, b) => order.indexOf(a.code) - order.indexOf(b.code)
        );

        return sortedItems;
      } else {
        return this.items;
      }
    },
  },

  methods: {
    isBrand() {
      return (
        auth.user &&
        auth.user.account &&
        auth.user.account.organization_registry &&
        auth.user.account.organization_registry.type_details &&
        auth.user.account.organization_registry.type_details.short_code ===
          "brand"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.account-manager-dashboard {
  a:not(.md-button):hover {
    text-decoration: none;
  }

  .md-card.md-theme-default:hover {
    .title-style {
      font-weight: 700;
      font-size: 20px;
    }
    box-shadow: 0px 29px 15px rgba(169, 169, 194, 0.19);
  }

  .card-style {
    background: #ffffff;
    box-shadow: 0px 6px 11px rgba(187, 187, 219, 0.19);
    border-radius: 8px;
  }
  .icon-container-purple {
    background-color: #ebdaff;
    border-radius: 8px 8px 0px 0px;
  }

  .icon-container-green {
    background-color: #a5e8e6;
    border-radius: 8px 8px 0px 0px;
  }

  .icon-container-disabled {
    background-color: #e5e5e5;
    border-radius: 8px 8px 0px 0px;
  }

  .media-content {
    justify-content: center;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 40px;
  }

  .media-content-management-dashboard {
    justify-content: center;
    display: inline-block;
    width: 140px;
    height: 100px;
    margin: 40px;
  }

  .title-style {
    color: #473068;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
  }

  .subtitle-style {
    color: #473068;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .hover-create_style .item-tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 10;
    background-color: white;
    color: $primaryDark;
    top: calc(0% + 100px);
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-size: 15px;
    font-weight: bold;
  }

  .hover-create_style:hover .item-tooltip {
    visibility: visible;
  }
}
</style>
