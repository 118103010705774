<template>
  <div class="modal-container" v-if="show == true">
    <div class="modal">
      <md-card class="md-primary">
        <div class="close">
          <md-button class="md-icon-button" @click="clearFeatureNotifications">
            <md-icon>close</md-icon>
          </md-button>
        </div>

        <md-card-header>
          <md-icon class="md-size-3x">verified</md-icon>
          <div class="md-title">New Feature!</div>
        </md-card-header>

        <md-card-content v-for="(feature, index) in newFeatures" :key="index">
          <h3>{{ feature.title }}</h3>
          {{ feature.description }}
        </md-card-content>

        <div class="actions">
          <md-button
            class="md-raised"
            v-if="currentPage !== 1"
            @click="getpreviousFeature"
            >Previous</md-button
          >

          <md-button
            class="md-raised"
            v-if="currentPage !== lastPage"
            @click="getNextFeature"
            >Next</md-button
          >

          <md-button
            class="md-raised"
            v-if="currentPage == lastPage"
            @click="clearFeatureNotifications"
            >Got It</md-button
          >
        </div>
      </md-card>
    </div>
  </div>
</template>

<script>
import restAdapter from "../../restAdapter";
import auth from "../../auth";

export default {
  name: "FeatureNotifications",
  data: () => ({
    newFeatures: [],
    currentPage: 1,
    lastPage: null,
    show: false,
  }),
  mounted() {
    this.getFeatureNotifications().then((response) => {
      const data = response.data.features;
      this.newFeatures = response.data.features.data;
      this.lastPage = data.last_page;

      if (this.newFeatures.length) {
        this.show = true;
      }
    });
  },
  methods: {
    getFeatureNotifications() {
      return restAdapter.get("/api/feature_notifications");
    },
    async getNextFeature() {
      const response = await restAdapter.get(
        `/api/feature_notifications?page=${this.currentPage + 1}`
      );
      this.newFeatures = response.data.features.data;
      this.currentPage++;
    },
    async getpreviousFeature() {
      const response = await restAdapter.get(
        `/api/feature_notifications?page=${this.currentPage - 1}`
      );
      this.newFeatures = response.data.features.data;
      this.currentPage--;
    },
    clearFeatureNotifications() {
      this.show = false;
      return restAdapter.delete("/api/clear_feature_notifications");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.md-card {
  width: 50%;
  height: 400px;
  margin: 4px;
  display: inline-block;
  vertical-align: top;
  overflow: auto;
}
.md-card-content {
  text-align: justify;
  margin: 10px;
}
.close {
  vertical-align: top;
  text-align: right;
}
.actions {
  vertical-align: bottom;
  text-align: center;
}
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 999;
}
::-webkit-scrollbar {
  display: none;
}

.md-card.md-theme-default.md-primary {
    background-image: linear-gradient(315deg, #eef0fb 0%, #c6a8ec 95%) !important;
    color: #fff !important;
}
</style>
