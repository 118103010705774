var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"md-layout md-gutter account-manager-dashboard"},_vm._l((_vm.filteredItems),function(item){return _c('v-col',{key:item.title,attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"3"}},[_c('router-link',{class:`hover-${item.code}`,attrs:{"to":item.code === 'create_style' && _vm.isCreateStyleButtonDisabled
            ? ''
            : item.path}},[_c('div',{staticClass:"md-layout-item account-manager-dashboard__item"},[_c('md-card',{staticClass:"card-style",style:(item.code === 'create_style' && _vm.isCreateStyleButtonDisabled
                ? 'pointer-events: none; opacity: 0.4; box-shadow: none;'
                : '')},[_c('div',{class:item.code === 'create_style' && _vm.isCreateStyleButtonDisabled
                  ? 'icon-container-disabled'
                  : item.id % 2 === 0
                  ? 'icon-container-green'
                  : 'icon-container-purple'},[_c('md-card-media',{class:item.code === 'create_collection'
                    ? 'media-content-management-dashboard'
                    : 'media-content',attrs:{"md-medium":""}},[(
                    item.code === 'create_style' &&
                    !_vm.isCreateStyleButtonDisabled
                  )?_c('create-style-icon'):(item.code === 'create_collection')?_c('create-collection-icon'):_c('img',{attrs:{"src":item.image,"alt":item.alt}})],1)],1),_c('md-card-content',[_c('div',{staticClass:"title-style"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"subtitle-style"},[_vm._v(_vm._s(item.description))])])],1),(item.code === 'create_style' && _vm.isCreateStyleButtonDisabled)?_c('div',{staticClass:"item-tooltip"},[_vm._v(" Please create a collection first ")]):_vm._e()],1)])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }